import React, {useEffect, useRef} from 'react';
import {logHelper, tLogStyled} from 'utils/Logger';
import {useParseGLTFAssets, usePreloadGLTFAssets} from 'webgl/hooks';
import {Asset} from 'interfaces';
import {loadingStore} from 'webgl/stores';
import WebGLCanvas from 'webgl/components/WebGLCanvas';

const WebGL: React.FC = () => {

  const percentageLoaded = loadingStore(state => state.percentageLoaded);
  useEffect(() => tLogStyled('Global loading progress', logHelper.loading, `${percentageLoaded}%`), [percentageLoaded]); // TODO DEBUG

  const gltfAssets = useRef([{ // replace the service call to get assetList
    key: 'carsat_socle_machines',
    url: './assets/glb/carsat_ensoleillement.glb',
    fileSize: 86_539_976 // TODO CHANGE FILE SIZE
  }]);

  usePreloadGLTFAssets<Asset[]>(gltfAssets.current); // Load files
  useParseGLTFAssets();


  return (
    <WebGLCanvas/>
  );
};

export default WebGL;