import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {sizeClassStore} from 'stores';
import {fixedAnnotationStore, FSMStore} from 'webgl/stores';
import {getAnnotationData__JSON} from 'services/getAnnotationData'; // TODO REMOVE
import './AnnotationBase.scss';
import './AnnotationBoxThumbnail.scss';
import {Object3D} from 'three/src/core/Object3D';
import {AnnotationData} from 'webgl/types/AnnotationData';
import {useHandleGotoState} from 'webgl/hooks';

function AnnotationBoxThumbnailItem(props: { description: AnnotationData, object: Object3D }) {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const handleGotoState = useHandleGotoState(props.object);
  const isPointerDown = useRef<boolean>(false);
  const currentFSMState = FSMStore(state => state.currentFSMState);

  const selected = useMemo<boolean>(() => {
    const {tags} = props.object.userData;
    return currentFSMState === tags?.gotoState;
  }, [currentFSMState, props.object.userData]);

  const onPointerDown = useCallback(() => isPointerDown.current = true, []);
  const onPointerUp = useCallback(() => {
    if (!isPointerDown.current) return;
    handleGotoState();
    isPointerDown.current = false;
  }, [handleGotoState]);
  return <div
    className={`annotation boxThumbnail blurred-background ${sizeClass} ${selected ? 'boxThumbnailSelected' : ''}`}
    onPointerDown={onPointerDown}
    onPointerUp={onPointerUp}>
    <div className={`description ${sizeClass}`}>{props.description.title}</div>
    <div className={`thumbnail ${sizeClass}`}><img alt={'protection thumbnail'} src={props.description.thumbnail}/>
    </div>
  </div>;
}

const AnnotationOrangeFixedDescriptions: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  // fixedAnnotationObjects are set in Annotations.tsx
  const fixedAnnotationObjects = fixedAnnotationStore(state => state.fixedAnnotationObjects);
  // const setFixedAnnotationDescriptions = fixedAnnotationStore(state => state.setFixedAnnotationDescriptions);
  // const fixedAnnotationDescriptions = fixedAnnotationStore(state => state.fixedAnnotationDescriptions);

  const [fixedAnnotationDescriptions, setFixedAnnotationDescriptions] =
    useState<Array<{ description: AnnotationData, object: Object3D }>>([]);

  useEffect(() => {
    setFixedAnnotationDescriptions([]); // reset list when objects change before waiting for backend response

    // getAnnotationsData__JSON(fixedAnnotationObjects) // TODO Retrieve Annotation descriptions from backend
    //   .then(res => setFixedAnnotationDescriptions(res));

    // const tmp: Array<{ description: AnnotationData, object: Object3D }> = [];
    const promiseArray: Array<Promise<{ description: AnnotationData, object: Object3D }>> = [];
    fixedAnnotationObjects.forEach(obj => {
      promiseArray.push(new Promise<{ description: AnnotationData, object: Object3D }>((resolve, reject) => {
        getAnnotationData__JSON(obj)
          .then(res => {
            if (res !== undefined)
              resolve({description: res, object: obj});
          });
      }));
    });
    // setFixedAnnotationDescriptions(tmp);
    Promise.allSettled(promiseArray).then(promiseResults => {
      // @ts-ignore value is not recognized??
      const data = promiseResults.filter(res => res.status === 'fulfilled').map(res => res.value);
      setFixedAnnotationDescriptions(data);
    });
  }, [fixedAnnotationObjects, setFixedAnnotationDescriptions]);

  if (!fixedAnnotationDescriptions || fixedAnnotationDescriptions.length < 1) return null;

  return (
    <div className={`fixedBoxThumbnailAnnotationsContainer ${sizeClass}`}>
      {fixedAnnotationDescriptions.map(({description, object}) =>
        <AnnotationBoxThumbnailItem description={description} object={object}/>
      )}
    </div>
  );
};

export default AnnotationOrangeFixedDescriptions;
