import React, {useCallback, useEffect, useState} from 'react';
import {configurationStore, FSMStore, loadingStore} from 'webgl/stores';
import {displayWebGLCanvas} from 'webgl/services/webglService';
import {FSMStates} from 'webgl/types/FSMStates';
import Spinner from 'components/Shared/Spinner';

import logoCarsat from 'assets/images/logo_carsat_zone_protection.jpg';
import './Homepage.scss';

const Homepage: React.FC = () => {
  const [displaySpinner, setDisplaySpinner] = useState<boolean>(false);
  const [waitingEnter, setWaitingEnter] = useState<boolean>(false);
  const percentageLoaded = loadingStore(state => state.percentageLoaded);
  const setFSMState = FSMStore(state => state.setFSMState);
  const {isConfiguration3dReady, setConfigurationReadiness} = configurationStore(state => ({
    isConfiguration3dReady: state.isConfiguration3dReady,
    setConfigurationReadiness: state.setConfiguration3dReadiness
  }));

  const onEnter = useCallback(() => {
    setWaitingEnter(true); // Enter clicked => waiting for loading to finish
  }, []);

  useEffect(() => {
    setDisplaySpinner(!isConfiguration3dReady && waitingEnter);

    if (isConfiguration3dReady && waitingEnter) {
      displayWebGLCanvas(true);
      // setFSMState(FSMStates.webgl.main);
      setFSMState(FSMStates.webgl.benefices);
    }
  }, [isConfiguration3dReady, setFSMState, waitingEnter]);

  return (
    <div className={'homepage'}>
      <span className={'flex-1'}/>
      <div className={'homepage-group flex-1'}>
        <img alt={'CARSAT logo'} src={logoCarsat}/>
        <div className={'homepage-title'}>Lumière naturelle</div>
      </div>
      <div className="flex flex-1 flex-center">
        <div className={'homepage-button-enter'} onClick={onEnter}>Entrer</div>
      </div>
      <div className={`homepage-loader ${displaySpinner ? 'visible' : 'hidden'}`}>
        <Spinner percentage={Math.round(percentageLoaded)}/>
      </div>
      <div className={`homepage-loader-bar ${!isConfiguration3dReady ? 'visible' : 'hidden'}`}
        style={{width: percentageLoaded + '%'}}/>
    </div>
  );
};

export default Homepage;