import React, {useCallback, useEffect, useState} from 'react';
import {FSMStore} from 'webgl/stores';
import {FSMStates} from 'webgl/types/FSMStates';

import thermometer from 'assets/images/thermometre@2x.png';
import './Thermometer.scss';

const Thermometer: React.FC = () => {
  const currentFSMState = FSMStore(state => state.currentFSMState);

  if (currentFSMState !== FSMStates.webgl.risques_sub.pt2) return null;

  return (
    <div className={`thermometer`}/* onClick={gotoWebGLMainState}*/>
      <img alt={'thermometer'} src={thermometer}/>
    </div>
  );
};

export default Thermometer;
