import React, {useCallback, useEffect, useState} from 'react';
import {FSMStore} from 'webgl/stores';
import {FSMStates} from 'webgl/types/FSMStates';

import logoCarsat from 'assets/images/logo_carsat_zone_protection.jpg';
import './Header.scss';

const Header: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const setFSMState = FSMStore(state => state.setFSMState);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const isSubStateOf = FSMStore(state => state.isSubStateOf);

  useEffect(() => {
    // setVisible(currentFSMState !== FSMStates.hidden); // hide only when homepage is visible
    setVisible(isSubStateOf(FSMStates.webgl));
  }, [currentFSMState, isSubStateOf]);

  const gotoWebGLMainState = useCallback(() => {
    setFSMState(FSMStates.webgl.main);
  }, [setFSMState]);

  if (!visible) return null;

  return (
    <div className={`header`}/* onClick={gotoWebGLMainState}*/>
      <img alt={'CARSAT logo'} src={logoCarsat}/>
      <div className={'header-title flex flex-center'}>Lumière naturelle</div>
    </div>
  );
};

export default Header;
