import {Material, Mesh, Object3D, Vector3} from 'three';
import {FSMStore} from 'webgl/stores';
import {useEffect} from 'react';
import {logHelper, tLogStyled} from 'utils/Logger';
import {useFrame} from '@react-three/fiber';
import {DefaultValues} from 'webgl/types/DefaultValues';

export const useHandleObjectVisibility = (scene: Object3D): void => {
  const currentFSMState = FSMStore(state => state.currentFSMState);

  useEffect(() => {
    if (scene) {
      const isValidRecursive = (validStates: string[], currentFSMState: string): boolean => {
        if (!validStates || validStates.length < 1 || !currentFSMState) return false;

        if (validStates.includes(currentFSMState)) return true;
        else {
          const parent = currentFSMState.split('.').slice(0, -1); // remove last element
          return isValidRecursive(validStates, parent.join('.'));
        }
        return false;
      };

      tLogStyled('[useHandleObjectVisibility] Setting objects visibility...', logHelper.processing);
      scene.traverse(node => {
        const activeStates: string[] = node.userData.tags?.activeStates?.split('&');
        const invertStates: boolean = node.userData.tags?.invertStates;
        const castShadowsWhenInvisible: boolean = node.userData.tags?.castShadowsWhenInvisible;
        if (activeStates !== undefined || invertStates !== undefined) {
          const isActive = isValidRecursive(activeStates, currentFSMState!);
          const isVisible = invertStates ? !isActive : !!isActive;
          node.traverse(child => {
            child.visible = isVisible;

            const mesh = child as Mesh;
            const mat = mesh.material as Material;
            if (!isVisible && castShadowsWhenInvisible && mat) {
              // make object invisible but still render its shadows
              child.visible = true;
              child.userData.originalMat ??= mat;

              mesh.material = DefaultValues.invisibleCastingShadowMaterial;
              console.log('Mesh ' + child.name + ' is now invisible but still casts shadows', mesh.material);
            }

            if (isVisible && castShadowsWhenInvisible && mat) {
              // reset properties
              if (child.userData.originalMat) {
                mesh.material = child.userData.originalMat;
                console.log('Reset material of ' + child.name + ' => ', mesh.material);
              } else {
                console.warn('Couldn\'t reset material of ' + child.name + ' => originalMat is undefined');
              }
            }
          });
        }
      });
    }
  }, [currentFSMState, scene]);

  // TODO hack to hide chicks when below lowest crate
  const v3 = new Vector3();
  useFrame(({scene}) => {
    scene.traverse(node => {
      if (node.name.toLocaleLowerCase().includes('poussin') && node.type === 'Mesh') {
        node.visible = node.getWorldPosition(v3).y >= 0.45;
      }
    });
  });
};