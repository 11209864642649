import React, {useEffect, useState} from 'react';
import Slider from 'rc-slider';
import {cameraStore, FSMStore, skyRigStore} from 'webgl/stores';
import {useRaf} from 'react-use';
import {Vector3} from 'three';
import {FSMStates} from 'webgl/types/FSMStates';
import {Season} from 'webgl/types/season';
import {DefaultValues} from 'webgl/types/DefaultValues';

import 'rc-slider/assets/index.css';
import './SkyControls.scss';

import {ReactComponent as IconWinter} from 'assets/icons/icon-winter.svg';
import {ReactComponent as IconSummer} from 'assets/icons/icon-summer.svg';
import {ReactComponent as WindRose} from 'assets/icons/wind_rose_text.svg';

const seasonMarks = {
  // 0: 'Hiver',
  // 1: 'Ete',
};

const timeMarks = {
  // 0: 'matin',
  .5: 'midi',
  // 1: 'soir',
};

const SkyRig: React.FC = (props) => {
  const isSubStateOf = FSMStore(state => state.isSubStateOf);
  const hideSkyControls = skyRigStore(state => state.hideSkyControls);
  const setSeason = skyRigStore(state => state.setSeason);
  const setTime = skyRigStore(state => state.setTime);
  const time = skyRigStore(state => state.time);
  const season = skyRigStore(state => state.season);
  const cameraBrain = cameraStore(state => state.cameraBrain);
  const [timeState, setTimeState] = useState<number>(DefaultValues.skyRigTime);
  const [seasonState, setSeasonState] = useState<Season>(DefaultValues.skyRigSeason);

  useEffect(() => {
    setSeason(DefaultValues.skyRigSeason);
    setTime(DefaultValues.skyRigTime);
  }, [setSeason, setTime]);
  
  useEffect(() => {
    setSeasonState(season);
    setTimeState(time);
  }, [time, season]);

  const elapsed = useRaf(1e11, 1000);
  const cameraDirection = cameraBrain?.getWorldDirection(new Vector3());
  const windRoseRotation = (cameraDirection && Math.atan2(cameraDirection.x, cameraDirection.z) + Math.PI) || 0

  if (!isSubStateOf(FSMStates.webgl) || isSubStateOf(FSMStates.webgl.risques_sub) || hideSkyControls) return null;

  return (
    // <div className={'sky-controls-container'}>
    <div className={'sky-controls-container'}>

      <div className="sky-controls-wrapper" style={{flex: 3}}>
        <div className="sky-controls-title">Position du soleil</div>
        <div className="sky-controls-label-before label-disabled"
          onClick={() => {
            setTime(0);
            setTimeState(0);
          }}>MATIN</div>
        <div className="sky-controls sky-controls-time">

          <Slider value={timeState} defaultValue={timeState} min={0} max={1} step={0.001} marks={timeMarks}
            onChange={(e) => {
              setTime(e as number);
              setTimeState(e as number);
            }}/>

        </div>
        <div className="sky-controls-label-after label-disabled"
          onClick={() => {
            setTime(1);
            setTimeState(1);
          }}>SOIR</div>
      </div>

      <div className="sky-controls-wrapper" style={{flex: 2}}>
        <div className="sky-controls-title">Saison</div>
        <div className={`sky-controls-label-before${seasonState === Season.WINTER ? '' : ' label-disabled'} clickable`}
          onClick={() => {
            setSeason(0);
            setSeasonState(0);
          }}>
          <IconWinter/>
          HIVER
        </div>
        <div className="sky-controls sky-controls-season">
          <Slider value={seasonState} defaultValue={seasonState} min={0} max={1} step={1} marks={seasonMarks}
            onChange={(e) => {
              setSeason(e as number);
              setSeasonState(e as number);
            }}
          />
        </div>
        <div className={`sky-controls-label-after${seasonState === Season.SUMMER ? '' : ' label-disabled'} clickable`}
          onClick={() => {
            setSeason(1);
            setSeasonState(1);
          }}>
          <IconSummer/>
          ETE
        </div>
      </div>

      <div className="sky-controls-wrapper" style={{flex: 2}}>
        <div className="sky-controls-orientation">
          <WindRose style={{transform: `rotate(${windRoseRotation}rad)`}}/>
        </div>
      </div>

    </div>
    // </div>
  );
};

export default SkyRig;