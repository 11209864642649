import React, {useMemo, useRef} from 'react';
import {Environment, Plane, Sky, useHelper} from '@react-three/drei';
import {ReactThreeFiber, useFrame} from '@react-three/fiber';
import {CameraHelper, DirectionalLight, DirectionalLightHelper, FrontSide, MathUtils, Vector2, Vector3} from 'three';
import {cameraStore, controlsStore, skyRigStore} from 'webgl/stores';
import {Object3D} from 'three/src/core/Object3D';

// import {softShadows} from "@react-three/drei";
// softShadows();


type SkyRigProps = {
  distance?: number;
  sunPosition?: ReactThreeFiber.Vector3;
  elevation?: number;
  azimuth?: number;
  mieCoefficient?: number;
  mieDirectionalG?: number;
  rayleigh?: number;
  turbidity?: number;
};

const SkyRig: React.FC<SkyRigProps> = (props) => {
  const cameraBrain = cameraStore(state => state.cameraBrain);
  const isLerping = cameraStore(state => state.isLerping);
  const getTarget = controlsStore(state => state.getTarget);
  const sunPosition = skyRigStore(state => state.sunPosition);
  const elevation = skyRigStore(state => state.elevation);
  const lightColor = skyRigStore(state => state.lightColor);

  const lightPower = 0.01 * Math.sin(MathUtils.degToRad(Math.max(0, Math.min(90, elevation * 4))));
  // console.log(elevation * 4, lightPower);

  // Update light shadow and position using camera target & viewport physical width
  useFrame(() => {
    if (!cameraBrain) return;

    const target = getTarget() || new Vector3();

    light.position.set(sunPosition.x + target.x, sunPosition.y + target.y, sunPosition.z + target.z);
    light.target.position.set(target.x, target.y, target.z);

    const distance = isLerping ? 100 : cameraBrain?.position.distanceTo(target); // TODO when tweening distance is 0.00x
    // const distance = cameraBrain?.position.distanceTo(target); // TODO when tweening distance is 0.00x
    const vFOVrad = MathUtils.degToRad(cameraBrain.fov);
    const hFOVrad = 2 * Math.atan(Math.tan(vFOVrad / 2) * cameraBrain.aspect);
    const viewportWorldWidth = distance * 2 * Math.tan(hFOVrad / 2);
    // console.log(isLerping, distance);

    light.shadow.camera.left = -viewportWorldWidth;
    light.shadow.camera.right = viewportWorldWidth;
    light.shadow.camera.top = viewportWorldWidth;
    light.shadow.camera.bottom = -viewportWorldWidth;
    light.shadow.camera.updateProjectionMatrix();
  });

  const lightRef = useRef<Object3D>();
  const light = useMemo(() => {
    const light = new DirectionalLight(lightColor);
    light.intensity = elevation >= -0.1 ? 0.005 + lightPower : 0;
    light.castShadow = true;
    light.shadow.mapSize = new Vector2(2048, 2048);
    light.shadow.bias = -0.000001;
    light.shadow.normalBias = 0.1;

    light.shadow.camera.left = -25;
    light.shadow.camera.right = 25;
    light.shadow.camera.top = 25;
    light.shadow.camera.bottom = -25;

    return light;
  }, [elevation, lightColor, lightPower]);

  // useHelper({current: light.shadow.camera}, CameraHelper);
  // useHelper({current: light}, DirectionalLightHelper);

  return (
    <>
      <Sky
        distance={props.distance}
        sunPosition={[sunPosition.x, sunPosition.y, sunPosition.z]}
        rayleigh={1 + 2 * (1 - Math.sin(MathUtils.degToRad(elevation)))}
        // turbidity={5}
        // distance={props.distance}
        // turbidity={props.turbidity}
        // rayleigh={props.rayleigh}
        mieCoefficient={props.mieCoefficient}
        mieDirectionalG={0.7}
        // elevation={elevation || props.elevation}
        // azimuth={azimuth || props.azimuth}
        // dithering={true}
      />

      <primitive ref={lightRef} object={light}/>
      <primitive object={light.target}/>

      <hemisphereLight color={lightColor} intensity={0.001 + lightPower / 3}/>

      <Plane position={[0, -0.2, 0]} rotation={[Math.PI / 2, 0, 0]} scale={100} castShadow={true} receiveShadow={true}>
        <meshStandardMaterial color={0xff0000} shadowSide={FrontSide}/>
      </Plane>

      {/*<axesHelper scale={50}/>*/}

      <Environment files={'./assets/hdri/empty_warehouse_01_1k_edited.hdr'} background={false}/>
    </>
  );
};

export default SkyRig;