import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Homepage from 'components/Main/Homepage';
import Navigation from 'components/Main/Navigation';
import SidePanel from 'components/Main/SidePanel/SidePanel';
import CarrouselModal from 'components/Main/SidePanel/CarrouselModal';
import WebGL from 'webgl/components/WebGL';
// import AnnotationOrangeFixedDescriptions from 'webgl/components/Annotations/AnnotationOrangeFixedDescriptions';
import AnnotationBoxThumbnailDescription from 'webgl/components/Annotations/AnnotationBoxThumbnailDescription';

import './Main.scss';
import SkyControls from 'components/Main/SkyControls';
import StepList from 'components/Main/StepList/StepList';
import Thermometer from 'components/Main/Thermometer';

const Main: React.FC = () => {
  return (
    <div className={`main-container flex flex-center`}>
      <Routes>
        {/*<Route path={'/'} element={<Homepage/>}/>*/}
        <Route path="*" element={<Homepage/>}/>
      </Routes>

      <WebGL/>
      <SkyControls/>
      <StepList/>
      <SidePanel/>
      <CarrouselModal/>
      <Navigation/>
      {/*<AnnotationOrangeFixedDescriptions/>*/}
      <AnnotationBoxThumbnailDescription/>
      {/*<AnnotationDangerAlwaysOpened_FIXED annotationId={'annotation_main_non-conformite'} position={new Vector2(100, 50)} forcedAngle={ForcedAngle.topRight} activeState={FSMStates.webgl.main}/>*/}
      <Thermometer/>
    </div>
  );
};

export default Main;
