import React from 'react';
import {useFullpagePanel} from 'hooks';

import './SidePanelTitle.scss';

type SidePanelTitleProps = {
  marker?: string;
  title?: string;
}

const SidePanelTitle: React.FC<SidePanelTitleProps> = ({marker, title}) => {
  const [fullpage,] = useFullpagePanel();

  return (
    <div className={`side-panel-title  ${fullpage ? 'fullpage' : 'right'}`} style={!title ? {paddingRight: '0.625rem'} : void 0}>
      <svg className="side-panel-title-marker">
        <circle id="backCircle"/>
        <circle id="frontCircle"/>
        <text x="50%" y="50%" dy=".3em">{marker}</text>
      </svg>

      {title && <div className="side-panel-title-text">{title}</div>}
    </div>
  );
};

export default SidePanelTitle;