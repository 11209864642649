export const FSMStates = {
  notLoaded: 'notLoaded',
  loading: 'loading',
  loaded: 'loaded',
  parsing: 'parsing',
  parsed: 'parsed',
  loadingError: 'loadingError',
  hidden: 'hidden',
  // homepage: 'homepage',


  webgl: {
    main: 'webgl.main',
    benefices: 'webgl.benefices',
    benefices_sub: {
      pt1: 'webgl.benefices_sub.pt1',
      pt2: 'webgl.benefices_sub.pt2',
      pt3: 'webgl.benefices_sub.pt3',
    },
    risques: 'webgl.risques',
    risques_sub: {
      pt1: 'webgl.risques_sub.pt1',
      pt2: 'webgl.risques_sub.pt2'
    },
    solutions: 'webgl.solutions',
    solutions_sub: {
      pt1: 'webgl.solutions_sub.pt1',
      pt1_sub: {
        a: 'webgl.solutions_sub.pt1_sub.a',
        b: 'webgl.solutions_sub.pt1_sub.b',
        c: 'webgl.solutions_sub.pt1_sub.c',
        d: 'webgl.solutions_sub.pt1_sub.d',
        e: 'webgl.solutions_sub.pt1_sub.e',
      },
      pt2: 'webgl.solutions_sub.pt2',
      pt3: 'webgl.solutions_sub.pt3',
      // pt4: 'webgl.solutions_sub.pt4'
    },
    credits: 'webgl.credits'
  },


  // below not used, kept for backward compatibility
  line: {
    default: 'line.default'
    // filled dynamically when custom line config is received
  } as { [p: string]: any }
};
