import React, {useCallback} from 'react';
import {FSMStore} from 'webgl/stores';
import {useFullpagePanel, useGetNavigationData} from 'hooks';

import prevIcon from 'assets/icons/arrow-left.svg';
import mainIcon from 'assets/icons/main-icon.svg';
import nextIcon from 'assets/icons/arrow-right.svg';
import './Navigation.scss';
import {stepListStore} from 'stores';

const Navigation: React.FC = () => {
  const setFSMState = FSMStore(state => state.setFSMState);
  const currentStep = stepListStore(state => state.currentStep);

  const [fullpage, displayBackground] = useFullpagePanel();
  const [displaySubstateArrows, displayNavigation, prevState, nextState, parentState] = useGetNavigationData();

  const gotoPrevState = useCallback(() => prevState && setFSMState(prevState), [prevState, setFSMState]);
  // const gotoWebGLMainState = useCallback(() => setFSMState(FSMStates.webgl.main), [setFSMState]);
  const gotoCurrentStepItemState = useCallback(() => {
    if (currentStep && currentStep.state)
      setFSMState(currentStep.state);
  }, [currentStep, setFSMState]);
  const gotoParentState = useCallback(() => parentState && setFSMState(parentState), [parentState, setFSMState]);
  const gotoNextState = useCallback(() => nextState && setFSMState(nextState), [nextState, setFSMState]);

  if (displayNavigation) { // DISPLAY PREVIOUS HOME NEXT BUTTONS
    return (
      <div className={`navigation ${fullpage ? 'top-right' : 'bottom-right'} ${displayBackground ? 'background' : ''}`}>
        <div className={`navigation-button previous ${prevState ? '' : 'disabled'}`} onClick={gotoPrevState}>
          <img src={prevIcon} alt={'previous'}/>
          <div>PRECEDENT</div>
        </div>
        <div className="navigation-button-divider"/>
        {!displaySubstateArrows &&
          <div className="navigation-button main" onClick={gotoCurrentStepItemState}>
          <img src={mainIcon} alt={'return to main'}/>
        </div>}
        {displaySubstateArrows &&
          <div className="navigation-button main" onClick={gotoParentState}>
          <img src={mainIcon} alt={'return to main'}/>
        </div>}

        <div className="navigation-button-divider"/>
        <div className={`navigation-button next ${nextState ? '' : 'disabled'}`} onClick={gotoNextState}>
          <div>SUIVANT</div>
          <img src={nextIcon} alt={'next'}/>
        </div>
      </div>
    );


  }
    // else if (displaySubstateArrows) { // DISPLAY ARROWS AND CLOSE
    //   return (
    //     <div className="navigation">
    //       <div className={`navigation-button-circle previous ${prevState ? '' : 'hidden'}`} onClick={gotoPrevState}>
    //         <img src={prevCircleIcon} alt={'previous'}/>
    //       </div>
    //       <div className="navigation-button-circle close" onClick={gotoParentState}>
    //         <img src={closeCircleIcon} alt={'close'}/>
    //       </div>
    //       <div className={`navigation-button-circle next ${nextState ? '' : 'hidden'}`} onClick={gotoNextState}>
    //         <img src={nextCircleIcon} alt={'next'}/>
    //       </div>
    //     </div>
    //   );
  // }
  else return null;
};

export default Navigation;