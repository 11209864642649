import React, {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {addClass, removeClass} from 'utils/HtmlClassUtils';
import {sizeClassStore} from 'stores';
import {canvasStore, FSMStore} from 'webgl/stores';
import {FSMStates} from 'webgl/types/FSMStates';
import {ACESFilmicToneMapping, sRGBEncoding} from 'three';
import {Canvas} from '@react-three/fiber';
import Configuration3D from 'webgl/components/Configuration/Configuration3D';
import RenderController from 'webgl/components/RenderController';
import CameraControls from 'webgl/components/CameraControls/CameraControls';
import CameraWobble from 'webgl/components/CameraControls/CameraWobble';
import SkyRig from 'webgl/components/SkyRig';

import './WebGLCanvas.scss';

type WebGLCanvasProps = {
  allowBlur?: boolean
};

const WebGLCanvas: React.FC<WebGLCanvasProps> = ({allowBlur}) => {
  const webglDivRef = useRef<HTMLDivElement>(null);
  const isHidden = canvasStore(state => state.isHidden);
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const [shouldRegress, setRegress] = useState<boolean>(false);

  //#region Blur Canvas DIV

  const handleBlur = useCallback(() => {
    if (currentFSMState === FSMStates.loading ||
      currentFSMState === FSMStates.loaded
      // || currentFSMState === FSMStates.screenSaver
    ) {
      setRegress(true);
      addClass(webglDivRef, 'blurred');
    } else {
      setRegress(false);
      removeClass(webglDivRef, 'blurred');
    }
  }, [currentFSMState]);

  // State Management
  useEffect(() => {
    if (allowBlur) handleBlur();
  }, [allowBlur, currentFSMState, handleBlur]);

  // TODO DEBUG BELOW
  // const [ortho, setOrtho] = useState<boolean>(false);
  // useEffect(() => {
  //   const swapPerspectiveOrthographicCameras = () => {
  //     setOrtho(prevState => !prevState);
  //   };
  //
  //   window.addEventListener('keyup', swapPerspectiveOrthographicCameras);
  //   return () => window.removeEventListener('keyup', swapPerspectiveOrthographicCameras);
  // }, []);

  //#endregion

  return (
    <div
      ref={webglDivRef}
      className={`webgl-canvas ${sizeClass} ${isHidden ? 'hidden' : 'visible'}`}
    >
      <Canvas
        shadows
        dpr={[1, 1]}
        performance={{min: 0.1}}
        gl={{logarithmicDepthBuffer: true, antialias: true, preserveDrawingBuffer: true, alpha: false}}
        onCreated={({gl}) => {
          // if (gl.shadowMap.enabled) {
          //   gl.shadowMap.type = VSMShadowMap; // default PCFSoftShadowMap
          // }
          gl.outputEncoding = sRGBEncoding;
          gl.toneMapping = ACESFilmicToneMapping;
          gl.toneMappingExposure = 0.5;
        }}
        camera={{position: [8.7, 6.5, 11.4], fov: 90}}
        frameloop={'demand'}
      >
        {/*<OrthographicCamera makeDefault={!ortho} zoom={40}/>*/}
        {/*<PerspectiveCamera makeDefault={ortho} position={[8.7, 6.5, 11.4]} fov={90}/>*/}
        <CameraControls/>
        <CameraWobble/>
        {/*<CameraMainRotate leftAngle={-0.2} rightAngle={0.75} damping={0.02} activeState={FSMStates.webgl.main}/>*/}
        <Suspense fallback={null}>

          <RenderController/>
          {/*<Sphere scale={10}>*/}
          {/*  <meshStandardMaterial roughness={0} metalness={1}/>*/}
          {/*</Sphere>*/}

          <SkyRig distance={45000}/>
          <Configuration3D/>
          {/*<DebugCube/>*/}

          {/*<gridHelper/>*/}
          {/*<Effects/>*/}
          {/* Effects May cause errors because EffectComposer forces autoClear to false */}
          {/* Effects uses unbiased=false? causing color banding */}

        </Suspense>
      </Canvas>
    </div>
  );
};

export default WebGLCanvas;