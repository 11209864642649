import React, {useEffect, useState} from 'react';
import {FSMStore, skyRigStore} from 'webgl/stores';
import {getSidePanelContent__JSON} from 'services/getSidePanelContent';
import {useFullpagePanel} from 'hooks';
import {logHelper, tLogStyled} from 'utils/Logger';
import Carrousel from 'components/Main/SidePanel/Carrousel';
import SidePanelTitle from 'components/Main/SidePanel/SidePanelTitle';
import {CarrouselItem} from 'stores/carrouselStore';

import './SidePanel.scss';

const SidePanel: React.FC = () => {
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const setPreviousFSMState = FSMStore(state => state.setPreviousFSMState);
  const setOverrideNextState = FSMStore(state => state.setOverrideNextState);
  const setSeason = skyRigStore(state => state.setSeason);
  const setTime = skyRigStore(state => state.setTime);

  const [visible, setVisible] = useState<boolean>(false);
  const [marker, setMarker] = useState<string | undefined>();
  const [title, setTitle] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [carrouselItems, setCarrouselItems] = useState<CarrouselItem[] | undefined>();

  const [fullpage,] = useFullpagePanel();

  useEffect(() => {
    setMarker(undefined); // TODO add spinner
    setTitle(undefined); // TODO add spinner
    setDescription(undefined); // TODO add spinner

    getSidePanelContent__JSON(currentFSMState)
      .then(res => {
        tLogStyled('[SidePanel] received data ', logHelper.debug, res);

        setMarker(res.marker);
        setTitle(res.title);
        setDescription(res.description);
        setCarrouselItems(res.carrouselItems);

        if (res.season) setSeason(res.season);
        if (res.time) setTime(res.time);

        setOverrideNextState(res.overrideNextState);

        setVisible(res !== undefined); // hide if undefined


        // EMAIL
        // if (currentFSMState === FSMStates.webgl.contact) { // TODO HACK TO OPEN MAILTO LINK ON CONTACT STATE
        //   setVisible(false);
        //   setPreviousFSMState();

        //   // validate res.title as email address
        //   // const validateEmail = (email: string) => {
        //   //   return String(email)
        //   //     .toLowerCase()
        //   //     .match(
        //   //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   //     );
        //   // };
        //   const emailAddress = res.title;
        //   const subject = res.description;
        //   // if (validateEmail(emailAddress)) {
        //   if (emailAddress) {
        //     window.open(`mailto:${emailAddress}?subject=${subject}`, '_blank');
        //   }
        // }


      }).catch(err => {
      setVisible(false); // hide if undefined
    });

  }, [currentFSMState, setPreviousFSMState, setSeason, setTime]);

  if (!visible || (!title && !description)) return null;

  // TODO when sidepanel is opened, shift the camera so that camera target is centered in the 3d view left

  return (
    <div className={`side-panel ${fullpage ? 'fullpage' : 'right'}`}>
      <div className={`white-background ${fullpage ? 'visible' : 'hidden'}`}/>
      <SidePanelTitle marker={marker} title={title}/>
      <div className="vertical-line"/>
      {description &&
        <div className={`side-panel-content`}>
          <div className={`side-panel-content-inner ${carrouselItems ? 'limit-carrousel' : ''}`}
            style={{overflow: 'auto'}}
            dangerouslySetInnerHTML={{__html: description}}/>
          <Carrousel items={carrouselItems}/>
        </div>
      }
    </div>
  );
};

export default SidePanel;